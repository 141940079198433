import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import SelectCards01 from 'components/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Ethernet/SelectCards01';
import SelectCards02 from 'components/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Ethernet/SelectCards02';
import NavButtons from 'components/Outdoor_Cameras/IN-9008_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9008 Full HD Quick Installation",
  "path": "/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Ethernet/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "IN-9008 Full HD Quick Installation",
  "image": "../../../P_SearchThumb_IN-9008HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-9008 Full HD Quick Installation' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='IN-9008 Full HD Quick Installation' image='/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9008_HD/Schnell_Installation/PoE_or_WiFi/Ethernet/' locationFR='/fr/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Ethernet/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <Link to="/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/" mdxType="Link"><EuiButton color="danger" fill mdxType="EuiButton">Return to Section II</EuiButton></Link>
    <h2 {...{
      "id": "ii-d-1-ethernet-cable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-d-1-ethernet-cable",
        "aria-label": "ii d 1 ethernet cable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-d-1 Ethernet Cable`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3e1151696025879a8db4828689042b21/99072/IN-9008-Quick-Installation-II-D-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+ElEQVQoz6WSwW7EIAxE9/8/MqdQaSkQDCEGbNxq5TSbw6pKtz5wsHiGGc/t6x91e4MRkVJKrfUdmJmttQDwAiaiWmtrjYiOp0TkDG/bJiJPeIxBRGOMsm2dHlVK0SYz6wUR6T+1a9apIQRrbd+wxPh5vwOAcy7GGEIwxszzbIzJOU/TZK3VTz1hPam1NecPY7z3KaVlWQAgpQQAMcbWmvZ3t0WktbaTRJ0Ia035UYg4xjjmnt1+Dau8Usrh1m97HmPUWs9m6LhLISEiAPDeL8sSY0REZr4KMzMiruvqnEPE3jsz6yauxlMTx8yqQpX/Iduah8OzK/A3Lvk56ncDCjQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e1151696025879a8db4828689042b21/e4706/IN-9008-Quick-Installation-II-D-1.avif 230w", "/en/static/3e1151696025879a8db4828689042b21/d1af7/IN-9008-Quick-Installation-II-D-1.avif 460w", "/en/static/3e1151696025879a8db4828689042b21/899b7/IN-9008-Quick-Installation-II-D-1.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3e1151696025879a8db4828689042b21/a0b58/IN-9008-Quick-Installation-II-D-1.webp 230w", "/en/static/3e1151696025879a8db4828689042b21/bc10c/IN-9008-Quick-Installation-II-D-1.webp 460w", "/en/static/3e1151696025879a8db4828689042b21/6366e/IN-9008-Quick-Installation-II-D-1.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e1151696025879a8db4828689042b21/81c8e/IN-9008-Quick-Installation-II-D-1.png 230w", "/en/static/3e1151696025879a8db4828689042b21/08a84/IN-9008-Quick-Installation-II-D-1.png 460w", "/en/static/3e1151696025879a8db4828689042b21/99072/IN-9008-Quick-Installation-II-D-1.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3e1151696025879a8db4828689042b21/99072/IN-9008-Quick-Installation-II-D-1.png",
              "alt": "IN-9008 Full HD Quick Installation",
              "title": "IN-9008 Full HD Quick Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Connect the Ethernet cable with your camera´s Ethernet port. Then plug in the other end of the Ethernet cable to a free LAN port on your internet router.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards01 mdxType="SelectCards01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "ii-d-6-wifi-antenna",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-d-6-wifi-antenna",
        "aria-label": "ii d 6 wifi antenna permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-d-6 WiFi Antenna`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca40b1cc837722a623d09b46c5c7f84f/99072/IN-9008-Quick-Installation-II-D-6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAzklEQVQoz6WTy26EMAxF5/9/kSWbAJMXyDYh8aUSUVHVNppo5qyyuEeyrZvH+QGPzhwAVT2O4x35PE9VTSn1ygC899u2xRi998ycc27K+IGqAsg5l29yzs2x76iqElEIwTmnF/tFKaU5NoBSSn3HGI0xwzA455h5nudxHJ1zRNSUj4sqL8uyPJ/WWhExxkzTxMw1oKr/7xxCsNbGGEVkXdd934mIL26neW0AzFx3SylVAUBvSWq03unNhonI3zlfyPUkIvKrD13yXZKej/EF6uI8nNnLx1cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca40b1cc837722a623d09b46c5c7f84f/e4706/IN-9008-Quick-Installation-II-D-6.avif 230w", "/en/static/ca40b1cc837722a623d09b46c5c7f84f/d1af7/IN-9008-Quick-Installation-II-D-6.avif 460w", "/en/static/ca40b1cc837722a623d09b46c5c7f84f/899b7/IN-9008-Quick-Installation-II-D-6.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca40b1cc837722a623d09b46c5c7f84f/a0b58/IN-9008-Quick-Installation-II-D-6.webp 230w", "/en/static/ca40b1cc837722a623d09b46c5c7f84f/bc10c/IN-9008-Quick-Installation-II-D-6.webp 460w", "/en/static/ca40b1cc837722a623d09b46c5c7f84f/6366e/IN-9008-Quick-Installation-II-D-6.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca40b1cc837722a623d09b46c5c7f84f/81c8e/IN-9008-Quick-Installation-II-D-6.png 230w", "/en/static/ca40b1cc837722a623d09b46c5c7f84f/08a84/IN-9008-Quick-Installation-II-D-6.png 460w", "/en/static/ca40b1cc837722a623d09b46c5c7f84f/99072/IN-9008-Quick-Installation-II-D-6.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca40b1cc837722a623d09b46c5c7f84f/99072/IN-9008-Quick-Installation-II-D-6.png",
              "alt": "IN-9008 Full HD Quick Installation",
              "title": "IN-9008 Full HD Quick Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Now attach the WiFi antenna and make sure that the antenna cable is still connected to the WiFi board (see step #3 `}<Link to="/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/" mdxType="Link">{`Here`}</Link>{`).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards02 mdxType="SelectCards02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "ii-d-11-reassemble",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-d-11-reassemble",
        "aria-label": "ii d 11 reassemble permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-d-11 Reassemble`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/761b02c121be48b24b2c2fc19c25e21e/99072/IN-9008-Quick-Installation-II-D-11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRUlEQVQoz51SXW/DIAzM//+BfU4fqiQlBEwhgD/IlHjtomratPkBIc6nM+frRAQRiQgRRWT7S3UxxmmajDG32y3GeMYQ8RfyD1it9f/k35XbqZi5tfbCiOitu7UmIq+ejpmJSEQej8f9fh/HERFTSsYYay0zKwcRa63rugJAjFHfu5daCGEYhr7vrbUAcLlchmFQce993/fzPKeUcs6llJzzThaRlFKMEQCWZbHWGmNSStM0jeOYc2Zm59yyLPEo59y6rrqXTo1RjI4S5hijneecsyrXWud5vl6vAJBSCiGUUr7cVsP0tmlUTs5t28ZEjFgPOwBAmztlMvNnzmqtpRBROyw5G70988fMpRRE3MlqtfdeT+/97sdTWUQAALwPB6QDiwgzfxMSEdERdBZdOBHpdOf8v/+5tRZCcM6FsCu9pf0tYR+n9zTg0GNUYQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/761b02c121be48b24b2c2fc19c25e21e/e4706/IN-9008-Quick-Installation-II-D-11.avif 230w", "/en/static/761b02c121be48b24b2c2fc19c25e21e/d1af7/IN-9008-Quick-Installation-II-D-11.avif 460w", "/en/static/761b02c121be48b24b2c2fc19c25e21e/899b7/IN-9008-Quick-Installation-II-D-11.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/761b02c121be48b24b2c2fc19c25e21e/a0b58/IN-9008-Quick-Installation-II-D-11.webp 230w", "/en/static/761b02c121be48b24b2c2fc19c25e21e/bc10c/IN-9008-Quick-Installation-II-D-11.webp 460w", "/en/static/761b02c121be48b24b2c2fc19c25e21e/6366e/IN-9008-Quick-Installation-II-D-11.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/761b02c121be48b24b2c2fc19c25e21e/81c8e/IN-9008-Quick-Installation-II-D-11.png 230w", "/en/static/761b02c121be48b24b2c2fc19c25e21e/08a84/IN-9008-Quick-Installation-II-D-11.png 460w", "/en/static/761b02c121be48b24b2c2fc19c25e21e/99072/IN-9008-Quick-Installation-II-D-11.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/761b02c121be48b24b2c2fc19c25e21e/99072/IN-9008-Quick-Installation-II-D-11.png",
              "alt": "IN-9008 Full HD Quick Installation",
              "title": "IN-9008 Full HD Quick Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Fasten the mainboard slide and use the 4 screws to mount the back plate back onto your camera casing. Screw the wall mount to the back of the camera.`}</p>
    <h2 {...{
      "id": "ii-d-12-final-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-d-12-final-installation",
        "aria-label": "ii d 12 final installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-d-12 Final Installation`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/07f60253be683da1715f9dd1296d4f8c/99072/IN-9008-Quick-Installation-II-D-12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABB0lEQVQoz5WS0ZKDIAxF/f+ftH3ouoKIEoI0gdIpmdqO1Z3uecogB+INTUqJiJj5WpG6fEeDiH3fW2u11kqpvu+NMd/K5Z/knJmZiGKMza1SSlkLqY9k+TvnnDFm/+YYY85595OEAgDjODZHO47klFIIQY54ye9tE9EfnVPlEZgEkJ4goq8w87IsiJhS2shcecjMvN5JRM65ruvath2GIVQ+/a289jNNkzEGALDivZd492V5YRIPEQHAPM/W2mmavPcAIP5mWrK/kQCdc/hEKaW1nudZegYAyXNfFl8e9nvIRISI1xrsa1Q5ryvNOqdc+RwLew/G/JxO+nL5PZ9N16UQSozldrsDaaU4DEnJ91AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07f60253be683da1715f9dd1296d4f8c/e4706/IN-9008-Quick-Installation-II-D-12.avif 230w", "/en/static/07f60253be683da1715f9dd1296d4f8c/d1af7/IN-9008-Quick-Installation-II-D-12.avif 460w", "/en/static/07f60253be683da1715f9dd1296d4f8c/899b7/IN-9008-Quick-Installation-II-D-12.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/07f60253be683da1715f9dd1296d4f8c/a0b58/IN-9008-Quick-Installation-II-D-12.webp 230w", "/en/static/07f60253be683da1715f9dd1296d4f8c/bc10c/IN-9008-Quick-Installation-II-D-12.webp 460w", "/en/static/07f60253be683da1715f9dd1296d4f8c/6366e/IN-9008-Quick-Installation-II-D-12.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07f60253be683da1715f9dd1296d4f8c/81c8e/IN-9008-Quick-Installation-II-D-12.png 230w", "/en/static/07f60253be683da1715f9dd1296d4f8c/08a84/IN-9008-Quick-Installation-II-D-12.png 460w", "/en/static/07f60253be683da1715f9dd1296d4f8c/99072/IN-9008-Quick-Installation-II-D-12.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/07f60253be683da1715f9dd1296d4f8c/99072/IN-9008-Quick-Installation-II-D-12.png",
              "alt": "IN-9008 Full HD Quick Installation",
              "title": "IN-9008 Full HD Quick Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Install your camera and plug in the power supply. The camera should automatically reconnect itself to your WiFi network.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      